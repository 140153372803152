import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO
        title="About | Photography art by Benjamin Hubert"
        desc="Hi. I'm Benjamin Hubert and I share my photos with you."
      />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Hi. I'm Benjamin Hubert!</h1>
        <p>
          I'm a photography enthusiast living and working in Vienna, Austria.
          I'm proud to share my best photos with you here on this site.
        </p>
        <p>
          Read more about me on <a href="https://benjaminhubert.at/">benjaminhubert.at</a>.
        </p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
